<template>
  <div class="app-container">
    <div class="bottom_box">
      <div class="content_t">
        <el-table
          :data="tableData"
          style="width: 100%"
          v-loading="listLoading"
          border
          fit
          highlight-current-row
          class="tableList3"
          :header-cell-style="{ background: '#F3F7F9', color: '#909399' }"
          height="100%"
          algin="center"
        >
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <el-table-column prop="owner" label="用户" v-if="userInfo.is_staff">
            <template slot-scope="scope">
              {{
                scope.row.owner
                  ? scope.row.owner.oauth_account
                    ? scope.row.owner.oauth_account
                    : scope.row.owner.username
                  : null
              }}
            </template>
          </el-table-column>
          <el-table-column prop="points_value" label="积分变动" width="150px">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.points_value > 0" type="success">
                {{ scope.row.points_value }}
              </el-tag>
              <el-tag v-else-if="scope.row.points_value < 0" type="warning">
                {{ scope.row.points_value }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column prop="details" label="详述"></el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            v-if="userInfo.is_staff"
          ></el-table-column>
          <el-table-column
            prop="operator"
            label="操作者"
            v-if="userInfo.is_staff"
          >
            <template slot-scope="scope">
              {{
                scope.row.operator
                  ? scope.row.operator.oauth_account
                    ? scope.row.operator.oauth_account
                    : scope.row.operator.username
                  : null
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="变动时间"
            width="300px"
          ></el-table-column>
        </el-table>
      </div>

      <div class="block" style="marginTop:10px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page_index"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getPointsMenu } from '@/api/points'
export default {
  data() {
    return {
      userInfo: this.$store.state.user.userInfo,
      tableData: [],
      listLoading: true,
      page_index: 1,
      page_size: 10,
      total: 0
    }
  },
  created() {
    this.getpointsMenu()
  },
  methods: {
    // 获取用户积分详情
    getpointsMenu() {
      this.listLoading = true
      // 管理员获取用户积分记录
      if (this.$route.query.id) {
        getPointsMenu({
          owner: this.$route.query.id,
          page_index: this.page_index,
          page_size: this.page_size
        }).then(resp => {
          this.tableData = resp.items
          this.total = resp.count
        })
      } else {
        // 用户获取积分记录
        getPointsMenu({
          // owner: this.$store.state.user.userInfo.id,
          page_index: this.page_index,
          page_size: this.page_size
        }).then(resp => {
          this.tableData = resp.items
          this.total = resp.count
        })
      }
      this.listLoading = false
    },
    handleSizeChange(val) {
      // 当每页显示条数发生变化时
      this.page_size = val
      this.getpointsMenu()
    },
    handleCurrentChange(val) {
      // 当页码发生变化时
      this.page_index = val
      this.getpointsMenu()
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom_box {
  width: 100%;
  height: calc(100vh - 128px);
  margin-top: 10px;
  border: 1px solid #e5e5e5;

  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  padding: 10px;
  .content_t {
    height: calc(100% - 50px);
    margin-top: 10px;
    // overflow: auto;
    ::v-deep td.el-table_1_column_1.el-table__cell {
      text-align: center;
    }
  }
}
</style>
